import React from "react";
import Layout from "../../templates/layout";
import SEO from "../../components/seo/seo";
import Hero from "../../components/hero/hero";
import Card from "../../components/card/card";
import HaveQuestions from "../../components/haveQuestions/haveQuestions";
import ExpandingCard from "../../components/ExpandingCard/ExpandingCard";
import CopayCard from "../../images/CareASSIST_copay card_v3_20220825-x.svg";
import Button from "../../components/button/button";
import SarclisaLogo from "../../images/logos/sarclisa_patient.svg";
import AffordIcon from "../../images/icons/icon-financial-sarclisa-hero.svg";
import QuestionsIcon from "../../images/icons/icon-questions-sarclisa.svg";
import Wallet from "../../images/icons/icon-wallet-sarclisa.png";
import Umbrella from "../../images/icons/icon-umbrella-sarclisa.svg";
import USA from "../../images/icons/icon-usa-sarclisa.svg";
import { Link } from "gatsby";
import BackToTop from "../../components/backToTop/BackToTop";
import PrescribingInfo from "../../components/prescribingInfo/prescribingInfo";
import Support from "../../images/icons/sarclisa/sarclisa-support-icon.svg"
import ThumbsUp from "../../images/icons/sarclisa/sarclisa-thumbsup.svg";
import ThumbsDown from "../../images/icons/sarclisa/sarclisa-thumbsdown.svg";

import AssistCallout from "../../components/assistCallout/AssistCallout";

const pageContent = () => (
  <Layout pageid="page-assistance" pageClass="patient-sarclisa-financial-assistance mobile-height-hero">
    <SEO
      title="CareASSIST financial support for SARCLISA® (isatuximab-irfc) | Patient Site"
      keywords="CareASSIST, Sanofi, SARCLISA® (isatuximab-irfc), Patient Site, Financial Assistance"
      description="Info for patients about CareASSIST Financial Assistance is here. Find out how much eligible patients may be able to save. See Prescribing Info, including Patient Info"
    />

    <Hero
      headline="Financial assistance"
      copy="CareASSIST offers programs and other support that may help you with the cost of SARCLISA. For more information, select the option that best describes your insurance coverage"
      brandLogo={SarclisaLogo}
      brandAlt="Sarclisa Logo"
      heroImgClass="hero-icon"
      hasLogo={true}
      hasIcon={true}
      heroIcon={AffordIcon}
      iconAlt="Financial Assistance Icon"
    />

    <section className="content-section">
    <AssistCallout imgAlt="Care Manager icon" productClass="sarclisa patient" img={Support} content="Your dedicated Care Manager is available to help you understand your options" />      
      <main className="elitek-financial">
        <div className="main-content">
          <ExpandingCard
            cardClass="full sarclisa bg-color"
            mainContent={() => (
              <div className="expanding-card-content header">
                <img alt="Thumbs up icon" src={ThumbsUp} />
                <h2>I have commercial insurance*</h2>
              </div>
            )}
            hiddenContent={() => (
              <div className="expanding-card-content hidden">
                <h3>The CareASSIST Copay Program</h3>
                <p>
                  Eligible patients may save money on their out-of-pocket costs.
                  We may be able to cover copays, coinsurance, and insurance
                  deductibles—up to $25,000 per year. Restrictions apply.
                </p>
                <figure className="copay-card m-b-20 mobile-m-b-0">
                  <div className="mobile-m-b-15">
                    <img src={CopayCard} alt="Copay card" className="mobile-m-b-0" />
                    <p className="m-b-0 fs-12 lh100">This card is an example </p>
                  </div>
                  <figcaption>
                    <p className="lh120 m-b-10">
                      Present your copay card along with your insurance card to your
                      doctor. If you have claims you would like to submit for
                      reimbursement consideration, please click{" "}
                      <a id="patient-sarclisa-trialcard"
                        href="https://portal.trialcard.com/sanofi/careassist"
                        target="_blank"
                        rel="noreferrer"
                        className="dont-break-out black underline"
                      >
                        here
                      </a>.{" "}
                      Follow the prompts to Upload Documents and upload your supporting documentation 
                      including a Pharmacy receipt and primary Explanation of Benefits (EOBs).
                    </p>
                    <h3 className="text-with-arrow m-t-15"><a id="patient-sarclisa-financial-copy-program" href="https://portal.trialcard.com/sanofi/careassist/" target="_blank" className="no-underline lh120 blue">Apply directly to our copay program</a></h3>
                  </figcaption>
                </figure>
                  <p className="lh120">
                    IMPORTANT NOTICE: Maximum benefit of $25,000 per calendar year. Prescription must be for an approved indication. Not valid for prescriptions covered by 
                    or submitted for reimbursement, in whole or in part, under Medicare, Medicaid, VA, DoD, TRICARE, or similar federal or state programs including any state 
                    pharmaceutical assistance programs. Not valid where prohibited by law. This offer is nontransferable, limited to one per person, and cannot be combined with 
                    any other offer or discount. Any savings provided by the program may vary depending on patients' out-of-pocket costs. Sanofi reserves the right to modify or 
                    discontinue the programs at any time without notice. All program details provided upon registration. 
                  </p>
                <hr className="m-b-20"/>
                <blockquote>
                  <h3 className="dark-blue">Out-of-pocket responsibility</h3>
                  <p>
                    After reaching the $25,000 limit, you are responsible for
                    any SARCLISA out-of-pocket
                    costs. You are also responsible for any
                    non–SARCLISA-specific costs related to supplies and
                    physician-related services.
                  </p>
                  <h3 className="dark-blue">Eligibility requirements</h3>
                  <ul>
                    <li>
                      <b>Insurance</b> - You must have commercial or private
                      insurance, which includes state or federal employee plans
                      and insurance purchased from health insurance exchanges
                    </li>
                    <li>
                      <b>Residency</b> - You must be a resident of the United
                      States or its territories or possessions
                    </li>
                    <li>
                      <b>Prescription</b> - Your prescription for SARCLISA from
                      your doctor must be in accordance with its FDA-approved
                      indication
                    </li>
                  </ul>
                  <p>
                    <b>
                      There is no income requirement for patients in this
                      program. Other conditions apply.
                    </b>
                  </p>
                </blockquote>
                  <p className="hang block">*Only for patients with commercial insurance. Also known as private insurance, commercial is the health coverage you obtain through a private company (for example, from your employer).</p>
              </div>
            )}
          />
          <ExpandingCard
            cardClass="full sarclisa bg-color"
            mainContent={() => (
              <div className="expanding-card-content header">
                <img alt="thumbs down icon" src={ThumbsDown} />
                <h2>I do not have commercial insurance or I lack coverage</h2>
              </div>
            )}
            hiddenContent={() => (
              <div className="expanding-card-content hidden">
                <h3>CareASSIST Patient Assistance Program</h3>
                <p>
                  Free medication for eligible patients.<sup>&dagger;</sup> Patients who do not
                  have commercial insurance or lack coverage for their
                  prescribed medication may be able to receive support through the Patient Assistance Program if they
                  meet the eligibility requirements below.{" "}
                </p>
                <blockquote>
                  <h3 className="dark-blue">Eligibility requirements</h3>
                  <figure className="resident flex-section">
                    <img src={Umbrella} alt="umbrella icon" />
                    <figcaption>
                      You must have no insurance coverage or lack coverage for
                      your prescribed medication
                      <ul className="mb-0 mt-0">
                        <li>
                          if you do have coverage, check out the <a
                          id="patient-sarclisa-copay-program"
                            href="https://portal.trialcard.com/sanofi/careassist"
                            target="_blank"
                            rel="noreferrer"
                          >Copay Program</a>
                        </li>
                        <li>
                          If you have Medicare Part B with no supplemental
                          insurance, you may still qualify for the PAP
                        </li>
                      </ul>
                    </figcaption>
                  </figure>
                  <figure className="uninsured flex-section">
                    <img src={USA} alt="united states icon" />
                    <figcaption>
                      You must be a resident of the United States or its territories or possessions and be under
                      the care of a licensed healthcare provider with a practice in the US
                      
                    </figcaption>
                  </figure>

                  <figure className="income flex-section">
                    <img src={Wallet} alt="wallet icon" width="46px" height="46px"/>
                    <figcaption>
                      You must have an annual household income that does not
                      exceed the greater of $100,000 or 500% of the current
                      Federal Poverty Level (FPL)
                    </figcaption>
                  </figure>
                </blockquote>
                <h3 className="text-with-arrow">
                  <a id="patient-sarclisa-financial-get-started" href="/sarclisa/enrollment" className="no-underline blue">Enroll in CareASSIST with your doctor to get started</a>
                </h3>
                <p className="hanging-indent fs-12 mb-0"><sup>&dagger;</sup>Provided by Sanofi Cares North America</p>
              </div>
            )}
          />
        </div>
      </main>
     
    <p className="pi-paragraph">Please see full <a id="patient-sarclisa-pi" href="https://products.sanofi.us/Sarclisa/sarclisa.pdf" target="_blank" rel="noreferrer">Prescribing Information</a>, including <a id="patient-sarclisa-patient-info" href="https://products.sanofi.us/Sarclisa/sarclisa_patient_information.pdf" target="_blank">Patient Information</a>.</p>
      <HaveQuestions icon={QuestionsIcon} />
    </section> <div className="back-to-top-container">      
      <BackToTop />
    </div>
  </Layout>
);

export default pageContent;
